import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { STYLE_CONSTANTS } from 'constants/style'

const Loader = () => {
  return (
    <LoaderWrapper data-testid="loaderWrapper">
      <CircularProgress data-testid="loaderCircular" />
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled.div`
  position: absolute;
  ${STYLE_CONSTANTS.CENTER_ABSOLUTE}
`

export default Loader
