import React, { useState, useEffect } from 'react'

import { GET_TEMPLATE_CATEGORIES } from 'graphql/queries'

import { useQuery, useMutation } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface TemplateCategoryDropDownPropsType {
  onCategoryChange: any
}

interface TemplateCategoriesData {
  templateCategories: TemplateCategory[]
}

interface TemplateCategoriesVars {}

const TemplateCategoryDropDown = (props: TemplateCategoryDropDownPropsType) => {
  const { onCategoryChange } = props
  const [templateCategories, setTemplateCategories] = useState<
    TemplateCategory[]
  >([])
  const [value, setValue] = React.useState<TemplateCategory>()
  const [inputValue, setInputValue] = React.useState('')

  const {
    loading: loadingGetTemplateCategories,
    error: errorGetTemplateCategories,
    data: dataGetTemplateCategories,
  } = useQuery<TemplateCategoriesData, TemplateCategoriesVars>(
    GET_TEMPLATE_CATEGORIES,
  )

  useEffect(() => {
    if (
      dataGetTemplateCategories &&
      dataGetTemplateCategories.templateCategories
    ) {
      setTemplateCategories(dataGetTemplateCategories.templateCategories)
      setValue(templateCategories[0])
    }
  }, [dataGetTemplateCategories])

  return (
    <Autocomplete
      value={value}
      onChange={(event: any, newValue: any) => {
        setValue(newValue)
      }}
      fullWidth
      inputValue={inputValue}
      onInputChange={(event: any, newInputValue: any) => {
        setInputValue(newInputValue)
        onCategoryChange(
          templateCategories.filter(
            (templateCategory) => templateCategory.category === newInputValue,
          )[0].id,
        )
      }}
      id="template-categories"
      options={templateCategories}
      getOptionLabel={(option) => option.category}
      renderInput={(params) => (
        <TextField {...params} label="Templates" variant="outlined" />
      )}
    />
  )
}

export default TemplateCategoryDropDown
