import { gql } from '@apollo/client'

import {
  FRAGMENT_TASK_FIELDS,
  FRAGMENT_TASK_VIRTUAL_FIELDS,
  FRAGMENT_USER_FIELDS,
  FRAGMENT_USER_OWNER_FIELDS,
  FRAGMENT_USER_AUTOCOMPLETE_FIELDS,
  FRAGMENT_PAGINATION_META_FIELDS,
} from 'graphql/fragments'

const SNIPPETS_QUERY_STRING = `
  snippets {
    id
    name
    value
    fallbackValue
    snippetType
  }
`
export const HAS_ERROR = gql`
  query HasError {
    hasError @client
  }
`
export const HAS_SUCCESS = gql`
  query HasSuccess {
    hasSuccess @client
  }
`

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    isLoggedIn @client
  }
`

export const GET_LOCAL_CACHED_DATA = gql`
  query LocalCachedData {
    hasError
    hasSuccess
    isLoggedIn
  }
`

export const GET_CLIENT_CONFIG = gql`
  query ClientConfig {
    clientConfig {
      defaultTaskFrequencyOneTime
      showOnboarding
      showFieldService
      showSnippets
      showBillingPortal
      showAssignUsersToLocation
      showLocale
      showAutomation
      showAuthorization
      showClientPages
      showEmailTaskType
      showNewAnalytics
      ssoOrgId
      showResources
      workosOrganizationId
      showPermissions
      enableSessionTracking
      hrisIntegrationSystem
      showDocusignTab
    }
  }
`

export const GET_WELCOME_EMAIL_SETTINGS = gql`
  query ClientWelcomeEmail {
    clientWelcomeEmail {
      clientId
      text
      daysBeforeStartDate
    }
  }
`

export const GET_CLIENT_BILLING_PORTAL = gql`
  query clientBillingPortal {
    clientBillingPortal {
      sessionUrl
    }
  }
`

export const GET_EMPLOYEE_ONBOARDING_ANALYTICS = gql`
  query employeeOnboardingAnalytics {
    employeeOnboardingAnalytics {
      feedbackUrl
      activityUrl
    }
  }
`

export const GET_CURRENT_USER = gql`
  query User {
    user {
      id
      isAdmin
      fullName
      email
      role {
        name
        roleType
        permissions {
          title
          entityType
          entityId
        }
      }
      clientId
    }
  }
`

export const GET_LOCATION_HIERARCHY_TREE = gql`
  query LocationHierarchyTree($topLevelCustomerId: ID!, $userId: ID) {
    locationHierarchyTree(
      topLevelCustomerId: $topLevelCustomerId
      userId: $userId
    )
  }
`
export const GET_USER_LOCATION_HIERARCHIES = gql`
  query UserLocationHierarchies($userId: ID!) {
    userLocationHierarchies(userId: $userId) {
      ownedLocationHierarchies {
        id
        templateCategoryId
      }
      assignedLocationHierarchies {
        id
        templateCategoryId
      }
    }
  }
`

export const GET_LOCATION_HIERARCHIES = gql`
  query LocationHierarchies($limitToAssignmentsIfAvail: Boolean!) {
    locationHierarchies(limitToAssignmentsIfAvail: $limitToAssignmentsIfAvail) {
      id
      title
    }
  }
`

export const GET_TASK_ANALYTICS_HOME_QUERY = gql`
  query TasksAnalytics(
    $date: ISO8601Date!
    $range: String
    $isCustomer: Boolean
    $query: String
    $sort: SortInput
    $pagination: PaginationInput
  ) {
    tasksAnalytics(
      date: $date
      range: $range
      isCustomer: $isCustomer
      query: $query
      sort: $sort
      pagination: $pagination
    ) {
      columns
      tree
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_ANALYTICS_HOME_QUERY = gql`
  query SessionsLocations($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    sessionsLocations(startDate: $startDate, endDate: $endDate) {
      assignedLocations {
        id
        fullHierarchyTitle
        state
        rootNode {
          owner {
            ...UserOwnerFields
          }
        }
      }
      managerialLocations {
        id
        fullHierarchyTitle
        state
        rootNode {
          owner {
            ...UserOwnerFields
          }
        }
      }
    }
    ${SNIPPETS_QUERY_STRING}
  }
  ${FRAGMENT_USER_OWNER_FIELDS}
`

export const GET_TASKS_VIRTUAL = gql`
  query TasksEvents(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $requireStartedSession: Boolean!
    $locationHierarchyId: ID!
    $isCustomer: Boolean
    $ignoreTaskAssignment: Boolean
  ) {
    tasksEvents(
      startDate: $startDate
      endDate: $endDate
      requireStartedSession: $requireStartedSession
      locationHierarchyId: $locationHierarchyId
      isCustomer: $isCustomer
      ignoreTaskAssignment: $ignoreTaskAssignment
    ) {
      ...TaskFieldsVirtual
      taskCompleted
      state(date: $startDate)
      complete(date: $startDate)
      lastImage(date: $startDate) {
        url
        eventId
      }
      lastImageUrl(date: $startDate)
      lastText(date: $startDate)
      lastCantCompleteText(date: $startDate)
      lastNotApplicableText(date: $startDate)
      lastCompleteEvent(date: $startDate) {
        user {
          email
        }
        createdAt
      }
    }
  }
  ${FRAGMENT_TASK_VIRTUAL_FIELDS}
`
export const GET_TASKS = gql`
  query Tasks($locationHierarchyId: ID!) {
    tasks(locationHierarchyId: $locationHierarchyId) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

export const GET_LOCATION_PHOTOS = gql`
  query LocationPhotos($locationHierarchyId: ID!, $date: ISO8601Date!) {
    locationPhotos(locationHierarchyId: $locationHierarchyId, date: $date) {
      id
      imageUrl
      user {
        email
      }
      createdAt
    }
  }
`

export const GET_LOCATION_TASKS_PHOTOS = gql`
  query LocationTasksPhotos($locationHierarchyId: ID!, $date: ISO8601Date!) {
    locationTasksPhotos(
      locationHierarchyId: $locationHierarchyId
      date: $date
    ) {
      id
      taskId
      imageUrl
      user {
        email
      }
      createdAt
    }
  }
`

export const GET_LOCATION_TASKS_TEXTS = gql`
  query LocationTasksTexts($locationHierarchyId: ID!, $date: ISO8601Date!) {
    locationTasksTexts(locationHierarchyId: $locationHierarchyId, date: $date) {
      id
      text
      taskId
      createdAt
    }
  }
`

export const GET_LOCATION_HIERARCHY = gql`
  query LocationHierarchy($locationId: ID!) {
    locationHierarchy(locationId: $locationId) {
      id
      fullHierarchyTitle
      rootNode {
        owner {
          ...UserOwnerFields
        }
      }
    }
  }
  ${FRAGMENT_USER_OWNER_FIELDS}
`

export const GET_CUSTOMERS = gql`
  query Customers {
    customers {
      id
      title
      state
    }
  }
`

export const GET_USER = gql`
  query User($id: ID!) {
    user(userId: $id) {
      ...UserFields
    }
  }
  ${FRAGMENT_USER_FIELDS}
`

export const GET_USERS = gql`
  query Users($query: String, $pagination: PaginationInput) {
    users(query: $query, pagination: $pagination) {
      data {
        ...UserFields
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_USER_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_ALL_USERS = gql`
  query Users($query: String) {
    users(query: $query, pagination: { paginate: false }) {
      data {
        ...UserFields
      }
    }
  }
  ${FRAGMENT_USER_FIELDS}
`

export const GET_USERS_AUTOCOMPLETE = gql`
  query Users($query: String, $pagination: PaginationInput) {
    users(query: $query, pagination: $pagination) {
      data {
        ...UserAutocompleteFields
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_USER_AUTOCOMPLETE_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_PAGES = gql`
  query Pages {
    contentPages(showAll: true) {
      id
      title
      taxonomyTermId
    }
  }
`

export const GET_PAGE = gql`
  query Pages($pageId: ID!) {
    contentPage(pageId: $pageId) {
      id
      slug
      title
      sections {
        id
        sectionType
        hidden
        content
        contentRaw
        pictureUrl
        icon1Url
        icon2Url
        icon3Url
        icon4Url
        icon5Url
        icon6Url
        icon7Url
        icon8Url
        icon9Url
        icon10Url
      }
    }
  }
`

export const GET_TEMPLATE_TASKS = gql`
  query TemplateTasks($categoryId: ID!) {
    templateTasks(categoryId: $categoryId) {
      id
      title
      description
      taskType
      mediaUrl
      tasksCount
      multipleOptionsId
      templateCategoryId
      showNotApplicableOption
      preBoarding
      important
      emailFrom
      estimateInMins
      notifyOnComplete
      notifyOnCantComplete
      templateTaskDateSetting {
        dateType {
          id
          name
        }
        days
        relation
      }
      templateRoles {
        id
        name
      }
      inactive
    }
  }
`

export const GET_TEMPLATE_CATEGORIES = gql`
  query TemplateCategories {
    templateCategories {
      id
      category
      categoryRaw
      inactive
    }
  }
`

export const GET_LOCATION_HIERARCHY_NOTES = gql`
  query LocationHierarchyNotes($locationHierarchyId: ID!) {
    locationHierarchyNotes(locationHierarchyId: $locationHierarchyId)
  }
`

export const GET_MULTIPLE_OPTIONS = gql`
  query MultipleOptions {
    multipleOptions {
      id
      clientId
      options {
        id
        option
      }
    }
  }
`

export const GET_TEMPLATE_ROLES = gql`
  query TemplateRoles {
    templateRoles {
      id
      name
    }
  }
`

export const GET_DATE_TYPES = gql`
  query DateTypes {
    dateTypes {
      id
      name
    }
  }
`

export const TEMPLATE_CATEGORY_HIERARCHY = gql`
  query TemplateCategoryHierarchy($id: ID!) {
    templateCategoryHierarchy(id: $id)
  }
`

export const TEMPLATE_CATEGORY_HIERARCHIES = gql`
  query TemplateCategoryHierarchies($ids: [ID!]!) {
    templateCategoryHierarchies(ids: $ids)
  }
`

export const GET_TAXONOMIES = gql`
  query Taxonomies {
    taxonomies {
      id
      name
      key
    }
  }
`

export const GET_TAXONOMY_TERM_HIERARCHY = gql`
  query TaxonomyTermHierarchy($taxonomyId: ID!) {
    taxonomyTermHierarchy(taxonomyId: $taxonomyId)
  }
`
export const GET_SNIPPETS = gql`
  query Snippets {
    ${SNIPPETS_QUERY_STRING}
  }
`

export const GET_WORKOS_ADMIN_PORTAL_URL = gql`
  query WorkosAdminPortalRedirectUrl {
    workosAdminPortalRedirectUrl {
      redirectUrl
    }
  }
`

export const GET_METABASE_REDIRECT_URL = gql`
  query MetabaseRedirectUrl {
    metabaseRedirectUrl {
      token
    }
  }
`

export const GET_AUTHORIZATION_TOKENS = gql`
  query AuthorizationTokens {
    authorizationTokens {
      id
      systemOfRecord
      tokenIssuedAt
      tokenIssuer
    }
  }
`

export const GET_AUTO_IMPORT_RULES = gql`
  query AutoImportRules {
    autoImportRules {
      id
      taxonomyTerms {
        id
        title
        taxonomyId
      }
      templateCategory {
        id
        category
      }
      rulesTemplateRolesUsers {
        userId
        user {
          id
          email
          firstName
          lastName
        }
        templateRoleId
      }
    }
  }
`

export const GET_CLIENT_SETTING = gql`
  query ClientSetting {
    clientSetting {
      taxonomyImportEnabled
      userImportEnabled
      docusign
      userImport {
        importJoining
        importLeaving
        joiningDays
        leavingDays
      }
    }
  }
`

export const GET_RESOURCES_HIERARCHY = gql`
  query ResourcesHierarchy($isEditing: Boolean) {
    resourcesHierarchy(isEditing: $isEditing) {
      id
      title
      titleRaw
      position
      parentId
      children {
        id
      }
      resources {
        id
        title
        titleRaw
        url
        position
        taxonomyTerms {
          id
          title
          taxonomyId
        }
      }
    }
  }
`

export const GET_PERMISSIONS = gql`
  query Permissions {
    permissions {
      id
      title
      children {
        id
        title
      }
    }
  }
`

export const GET_ROLE_PERMISSIONS = gql`
  query RolePermissions($roleId: ID!) {
    rolePermissions(roleId: $roleId) {
      visibility
      permissions {
        id
        title
      }
    }
  }
`
export const GET_ROLES = gql`
  query Roles {
    roles {
      id
      name
      roleType
    }
  }
`
export const GET_MERGE_TOKEN = gql`
  query MergeToken {
    mergeToken
  }
`

export const GET_USERS_WITHOUT_TEMPLATE_TASK = gql`
  query UsersWithoutTemplateTask($templateTaskId: ID!) {
    usersWithoutTemplateTask(templateTaskId: $templateTaskId) {
      id
      email
      firstName
      lastName
      fullName
      startDate
    }
  }
`
