import {
  JLL_CLIENT_ID,
  JLL_VERIFY_TOKEN_URL,
  JLL_DOMAINS,
  JLL_ISSUER_URL,
  SONOMABIO_CLIENT_ID,
  SONOMABIO_VERIFY_TOKEN_URL,
  SONOMABIO_DOMAINS,
  SONOMABIO_ISSUER_URL,
} from 'constants/static'

export type GetOktaCredentialsResponse = {
  oktaClientId?: string
  oktaVerifyTokenUrl?: string
  oktaIssuerUrl?: string
  isOktaDomain: boolean
}

export default function getOktaCredentials(
  domain: string,
): GetOktaCredentialsResponse {
  if (JLL_DOMAINS.includes(domain)) {
    return {
      oktaClientId: JLL_CLIENT_ID,
      oktaVerifyTokenUrl: JLL_VERIFY_TOKEN_URL,
      oktaIssuerUrl: JLL_ISSUER_URL,
      isOktaDomain: true,
    }
  } else if (SONOMABIO_DOMAINS.includes(domain)) {
    return {
      oktaClientId: SONOMABIO_CLIENT_ID,
      oktaVerifyTokenUrl: SONOMABIO_VERIFY_TOKEN_URL,
      oktaIssuerUrl: SONOMABIO_ISSUER_URL,
      isOktaDomain: true,
    }
  } else {
    return {
      isOktaDomain: false,
    }
  }
}
