export const COLORS = {
  DARK: '#384d48',
  MEDIUM_DARK: '#6e7271',
  MEDIUM: '#acad94',
  MEDIUM_LIGHT: '#d8d4d5',
  LIGHT: '#e2e2e2',
  RED: '#e74c3c',
  RED_LIGHT: '#f5cfcc',
  WHITE: '#fff',
  GREY1: '#808080',
  GREY2: '#212529',
  GREY3: '#b5b8bb',
  TURQUOISE: '#50CEA2',
  TURQUOISE_LIGHT: '#ccece1',
  YELLOW: '#FFCE55',
  LIGHT_GREY: '#f2f2f2',
  LIGHT_GREY2: '#F1F1F1',
  LIGHT_GREY3: '#273b4a',
  LIGHT_GREY4: 'rgba(256,256,257,0.2)',
  LIGHT_GREY5: '#CDD3DA',
  LIGHT_GREY6: '#DFE6ED',
  GRAY_INACTIVE: '#C7C7C7',
  NAVY_BLUE: '#242953',
  BLACK: '#000',
  BORDER_COLOR: 'hsl(0, 0%, 80%)',
  SECONDARY: '#E74035',
  GREEN_LIGHT: '#d1ead2',
  GREEN_MEDIUM: '#50CEA2',
  BLUE: '#6E92C5',
  ORANGE: '#FFC024',
  LIGHT_BLUE: '#f2f7ff',
  GRAY_BLUE: '#7C8DA3',
  GRAY_BLUE2: '#273B4A',
  LIGHT_GRAY_BLUE: '#D4DBE8',
  DARK_GRAY_BLUE: '#3E4475',
  DARK_GRAY_BLUE2: '#242953',
  DARK_GRAY: '#1D1D1D',
  DARK_GRAY2: '#1D2B3D',
}

export const FONT_SIZES = {
  MEDIUM: '0.9rem',
  SMALL: '0.8rem',
}

export const STYLE_CONSTANTS = {
  FLEX_CENTER: `
        display: flex;
        justify-content: center;
        align-items: center;
    `,
  CENTER_ABSOLUTE: `
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    `,
  FLEX_COLUMN: `
        display: flex;
        flex-direction: column;
    `,
  FONT_MEDIUM: `
    font-size: ${FONT_SIZES.MEDIUM};
    `,
  FONT_SMALL: `
    font-size: ${FONT_SIZES.SMALL};
    `,
}
