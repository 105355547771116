import { gql } from '@apollo/client'

// common task fields
const commonTaskFields = `
  id
  title
  description
  taskType
  frequencyType
  locationHierarchyId
  rrule
  mediaUrl
  taskCompleted
  templateTaskId
  isTemplatePublished
  templateCategory
  multipleOptionsId
  dueDate
  hideFromCustomer
  showNotApplicableOption
  emailFrom
  users {
    id
    email
  }
`

// fragment for schema tasks: __typename "Task"
export const FRAGMENT_TASK_FIELDS = gql`
  fragment TaskFields on Task {
    ${commonTaskFields}
    hasOwnDescription
    titleRaw
    descriptionRaw
  }
`

export const FRAGMENT_TEMPLATE_TASK_FIELDS = gql`
  fragment TemplateTaskFields on TemplateTask {
    id
    description
    descriptionRaw
    taskType
    title
    titleRaw
    mediaUrl
    isTemplatePublished
    tasksCount
    multipleOptionsId
    templateCategoryId
    templateRoles {
      id
      name
    }
    taxonomyTerms {
      id
      taxonomyId
    }
    templateTaskDateSetting {
      dateType {
        id
        name
      }
      days
      relation
    }
    inactive
    preBoarding
    important
    estimateInMins
    notifyOnComplete
    notifyOnCantComplete
  }
`
// fragment for virtual tasks: __typename "TaskVirtual"
export const FRAGMENT_TASK_VIRTUAL_FIELDS = gql`
  fragment TaskFieldsVirtual on TaskVirtual {
    ${commonTaskFields}
    locationHierarchy {
      id
      title
      logoUrl
      fullHierarchyTitle
      parentLogoUrl
      locationText(date: $startDate) {
        id
        text
        createdAt
      }
    }
  }
`

export const FRAGMENT_USER_FIELDS = gql`
  fragment UserFields on User {
    id
    email
    personalEmail
    firstName
    lastName
    isAdmin
    isSuperAdmin
    isTemp
    role {
      id
    }
    locationAssignments {
      id
      locationHierarchyId
    }
    taxonomyTerms {
      id
      taxonomyId
    }
    employee {
      manager {
        id
        email
        firstName
        lastName
      }
      startDate
    }
  }
`

export const FRAGMENT_USER_OWNER_FIELDS = gql`
  fragment UserOwnerFields on User {
    firstName
    lastName
    fullName
    email
    workEmail
    personalEmail
    taxonomyTerms {
      title
      id
      taxonomy {
        name
        slug
      }
    }
    employee {
      startDate
      manager {
        firstName
        lastName
        email
        fullName
      }
    }
  }
`

export const FRAGMENT_USER_AUTOCOMPLETE_FIELDS = gql`
  fragment UserAutocompleteFields on User {
    id
    email
    firstName
    lastName
  }
`

export const FRAGMENT_PAGINATION_META_FIELDS = gql`
  fragment PaginationMetaFields on PaginationMeta {
    page
    perPage
    totalPages
    totalCount
    nextPage
    prevPage
  }
`
