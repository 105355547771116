import { createContext, useContext } from 'react'

import { OktaAuth } from '@okta/okta-auth-js'

const AppContext = createContext(
  {} as {
    pageTitle: string | null
    setPageTitle: React.Dispatch<React.SetStateAction<string | null>>
    saveCrossDomainToken: (value: string) => void
    oktaAuth: OktaAuth
    setOktaAuth: React.Dispatch<React.SetStateAction<OktaAuth>>
  },
)

const useAppContext = () => {
  return useContext(AppContext)
}

export default AppContext

export { useAppContext }
