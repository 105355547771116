import { useQuery } from '@apollo/client'
import { isNull, omitBy } from 'lodash'

import { GET_CLIENT_CONFIG } from 'graphql/queries'

const defaultClientConfig: ClientConfig = {
  showOnboarding: false,
  showFieldService: false,
  showSnippets: false,
  defaultTaskFrequencyOneTime: false,
  showAssignUsersToLocation: false,
  showAutomation: false,
  showAuthorization: false,
  showBillingPortal: false,
  showLocale: false,
  showClientPages: false,
  showNewAnalytics: false,
  ssoOrgId: '',
  showEmailTaskType: false,
  showResources: false,
  workosOrganizationId: null,
  showPermissions: false,
  enableSessionTracking: false,
  hrisIntegrationSystem: '',
  showDocusignTab: false,
}

const useClientConfig = () => {
  const { data, loading } = useQuery<{ clientConfig: ClientConfig }>(
    GET_CLIENT_CONFIG,
    {
      fetchPolicy: 'cache-only',
    },
  )

  if (loading) {
    return { ...defaultClientConfig }
  }

  const config = omitBy(data?.clientConfig || {}, isNull)

  return {
    ...defaultClientConfig,
    ...config,
  }
}

export default useClientConfig
