import { TaskTypeEnum } from 'constants/types'

export const CACHE_KEYS = {
  TOKEN: 'authenticationToken',
}

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'authToken',
  CUSTOMERS_SEARCH_FIELD: 'customersSearchField',
  CUSTOMERS_STATUS_FIELD: 'customersStatusField',
  USER_EMAIL: 'userEmail',
}

export const SUPPORTED_VIDEO_TYPES = {
  MP4: 'mp4',
  MOV: 'mov',
}

export const PASSWORD_MIN_LENGTH = 6

export const DEFAULT_RRULE = `DTSTART:${new Date(
  +new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000),
)
  .toISOString()
  .substring(0, 10)
  .replace(/-/g, '')}T010000Z
  RRULE:FREQ=DAILY;INTERVAL=1`

export const GRAPHQL_INITIAL_STATE = {
  hasError: false,
  hasSuccess: false,
  isLoggedIn: !!localStorage.getItem('authToken'),
}

export const SONOMABIO_DOMAINS =
  process.env.REACT_APP_SONOMABIO_DOMAINS?.split(' ') || []
export const SONOMABIO_CLIENT_ID = process.env.REACT_APP_SONOMABIO_CLIENT_ID
export const SONOMABIO_OKTA_DOMAIN = process.env.REACT_APP_SONOMABIO_OKTA_DOMAIN
export const SONOMABIO_ISSUER_URL = `https://${SONOMABIO_OKTA_DOMAIN}`
export const SONOMABIO_VERIFY_TOKEN_URL = `https://${SONOMABIO_OKTA_DOMAIN}/oauth2/default/v1/token`

export const JLL_DOMAINS = process.env.REACT_APP_JLL_DOMAINS?.split(' ') || ['']

export const DOCUSIGN_INTEGRATION_KEY = process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY
export const DOCUSIGN_BASE_URL = process.env.REACT_APP_DOCUSIGN_BASE_URL
export const BACKEND_URL = process.env.REACT_APP_GRAPHQL_BACKEND_URL

export const JLL_CLIENT_ID = process.env.REACT_APP_JLL_CLIENT_ID
export const JLL_OKTA_DOMAIN = process.env.REACT_APP_JLL_OKTA_DOMAIN
export const JLL_ISSUER_URL = `https://${JLL_OKTA_DOMAIN}`
export const JLL_VERIFY_TOKEN_URL = `https://${JLL_OKTA_DOMAIN}/oauth2/default/v1/token`
export const OKTA_REDIRECT_PATH = '/sso-login'
export const CROSS_DOMAIN_STORAGE_URL =
  process.env.REACT_APP_CROSS_DOMAIN_STORAGE_URL

export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID

export const TASK_TYPES = [
  {
    title: 'Check Task',
    taskType: TaskTypeEnum.TOGGLE,
    hideForOnboardingClients: false,
  },
  {
    title: 'Photo Task',
    taskType: TaskTypeEnum.PHOTO,
    hideForOnboardingClients: true,
  },
  {
    title: 'E-Signature',
    taskType: TaskTypeEnum.SIGN,
    hideForOnboardingClients: false,
  },
  {
    title: 'Comment Task',
    taskType: TaskTypeEnum.TEXT,
    hideForOnboardingClients: false,
  },
  {
    title: 'Multiple Options',
    taskType: TaskTypeEnum.MULTIPLE_OPTION,
    hideForOnboardingClients: false,
  },
  {
    title: 'Email Notification',
    taskType: TaskTypeEnum.EMAIL,
    hideForOnboardingClients: false,
  },
  {
    title: 'DocuSign',
    taskType: TaskTypeEnum.DOCUSIGN,
    hideForOnboardingClients: false,
  },
]

export const LOCATION_HIERARCHY_STATES = [
  {
    value: 'ACTIVE',
    label: 'active',
  },
  {
    value: 'INACTIVE',
    label: 'inactive',
  },
  {
    value: 'PROFILE',
    label: 'profile',
  },
]

export const DATE_FORMAT_SHORT = 'yyyy-MM-dd'
export const STATIC_ROLES = ['Manager', 'Employee']

export const VALIDATION_SETTINGS = {
  EMAIL: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Invalid email address',
  },
}

export const AVAILABLE_LOCALES = [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'FR',
    value: 'fr',
  },
  {
    label: 'FR-CA',
    value: 'fr-CA',
  },
  {
    label: 'ES',
    value: 'es',
  },
  {
    label: 'PT',
    value: 'pt',
  },
  {
    label: 'JA',
    value: 'ja',
  },
  {
    label: 'DE',
    value: 'de',
  },
  {
    label: 'ZH-CN',
    value: 'zh-CN',
  },
  {
    label: 'ZH-TW',
    value: 'zh-TW',
  },
]
