import React, { ReactNode } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Backdrop, CircularProgress } from '@material-ui/core'

interface DeleteDialogProps {
  modalOpen: boolean
  handleNo: () => void
  handleYes: () => void
  content: string | ReactNode
  isLoading?: boolean
}

const DeleteDialog = ({
  handleNo,
  handleYes,
  modalOpen,
  content,
  isLoading,
}: DeleteDialogProps) => (
  <Dialog
    open={modalOpen}
    onClose={handleNo}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" data-testid="dialog-title">
      Delete confirmation
    </DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      <Button onClick={handleNo} color="primary" data-testid="dialog-no">
        No
      </Button>
      <Button
        onClick={handleYes}
        color="primary"
        autoFocus
        data-testid="dialog-yes"
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

export default DeleteDialog
