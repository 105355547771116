export const PUBLIC_ROUTE = {
  LOGIN: '/',
}

export const PRIVATE_ROUTE = {
  DASHBOARD: '/dashboard',
  LOCATION_PAGE: '/location-page',
  LOCATION_DETAILS: '/location-details',
  ANALYTICS: '/analytics',
  PAGES: '/pages',
  USERS: '/users',
}
