// cache keys
const lit = <V extends keyof any>(v: V) => v

export const CACHE_KEYS = {
  TASKS: lit('tasks'),
} as const

export enum TaskTypeEnum {
  PHOTO = 'PHOTO',
  TOGGLE = 'TOGGLE',
  SIGN = 'SIGN',
  TEXT = 'TEXT',
  DOCUSIGN = 'DOCUSIGN',
  OPTION = 'OPTION',
  MULTIPLE_OPTION = 'MULTIPLE_OPTION',
  EMAIL = 'EMAIL',
}

export enum TaskStateEnum {
  COMPLETE = 'COMPLETE',
  CANT_COMPLETE = 'CANT_COMPLETE',
  TODO = 'TODO',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum TaskFrequencyEnum {
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
}

export enum SnippetTypeEnum {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum DefaultDateTypeNames {
  START_DATE = 'Start Date',
  WORKFLOW_ASSIGNED_DATE = 'Workflow Assigned Date',
}
