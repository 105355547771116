import {
  ApolloClient,
  ApolloLink,
  FetchPolicy,
  InMemoryCache,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { captureException } from '@sentry/react'
import { createUploadLink } from 'apollo-upload-client'

import { CROSS_DOMAIN_STORAGE_URL, LOCAL_STORAGE_KEYS } from 'constants/static'
import { GET_LOCAL_CACHED_DATA } from 'graphql/queries'
import { resetCacheAndSetInitialValues } from 'utils/graphql'

const cache = new InMemoryCache()

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  }
})

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_GRAPHQL_BACKEND_URL}/graphql`,
  credentials: 'same-origin',
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network' as WatchQueryFetchPolicy,
  },
  query: {
    fetchPolicy: 'cache-and-network' as FetchPolicy,
  },
  mutate: {},
}

export default function createGraphqlClient() {
  const client = new ApolloClient({
    link: ApolloLink.from([
      // auth link
      authLink,
      // error link
      onError(({ graphQLErrors, networkError, operation }) => {
        // @ts-ignore
        if (networkError?.statusCode === 422) {
          return
        }
        if (graphQLErrors) {
          if (!process.env.CI) {
            captureException(graphQLErrors)
          }
          graphQLErrors.map(({ message, locations, path }) => {
            // client.writeData({ data: { hasError: message } })
            const data = client.readQuery({ query: GET_LOCAL_CACHED_DATA })
            client.writeQuery({
              query: GET_LOCAL_CACHED_DATA,
              data: { ...data, ...{ hasError: message } },
            })

            console.log(
              `[GraphQL error]: ${message}, Location: ${JSON.stringify(
                locations,
              )}, Path: ${path}`,
            )
          })
        }
        if (networkError) {
          if (!process.env.CI) {
            captureException(networkError)
          }
          console.log(`[Network error]: ${networkError}`)
          const { response } = operation.getContext()
          if (response?.status === 401) {
            localStorage.clear()
            resetCacheAndSetInitialValues(client)
            const iframe = document.querySelector<HTMLIFrameElement>(
              '#cross-domain-iframe',
            )

            if (iframe) {
              iframe!.contentWindow!.postMessage(
                {
                  action: 'save',
                  key: LOCAL_STORAGE_KEYS.TOKEN,
                  value: '',
                },
                CROSS_DOMAIN_STORAGE_URL || '*',
              )
            }

            // client.writeData({ data: { isLoggedIn: false } })
            const data = client.readQuery({ query: GET_LOCAL_CACHED_DATA })
            client.writeQuery({
              query: GET_LOCAL_CACHED_DATA,
              data: { ...data, isLoggedIn: false },
            })
            window.location.href = '/'
          }
          if (response?.status !== 401) {
            // client.writeData({ data: { hasError: 'An error occurred while accessing the server' } })
            const data = client.readQuery({ query: GET_LOCAL_CACHED_DATA })
            client.writeQuery({
              query: GET_LOCAL_CACHED_DATA,
              data: {
                ...data,
                hasError: 'An error occurred while accessing the server',
              },
            })
          }
        }
      }),
      // http link
      uploadLink,
    ]),
    defaultOptions,
    cache,
    connectToDevTools: true,
  })

  return client
}
