import React, { lazy, Suspense } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security, LoginCallback } from '@okta/okta-react'
import { createBrowserHistory } from 'history'

import { PUBLIC_ROUTE } from './routes.constants'
import { Loader } from 'components/Index'
import ErrorBoundary from 'components/common/ErrorBoundary'
import {
  JLL_ISSUER_URL,
  JLL_CLIENT_ID,
  OKTA_REDIRECT_PATH,
} from 'constants/static'
import { useAppContext } from 'contexts/appContext'
import lazyWithRetry from 'utils/lazyWithRetry'

const Dashboard = lazyWithRetry(
  () => import('./components/dashboard/Dashboard'),
)
const PasswordResetPage = lazyWithRetry(
  () => import('./components/password-reset/PasswordResetPage'),
)

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LOGIN,
    exact: true,
    component: lazyWithRetry(() => import('./components/login/Login')),
  },
]

interface Props {
  children: any
  path: string
  // any other props that come into the component
}

const history = createBrowserHistory()
const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
  history.push(toRelativeUrl(originalUri, window.location.origin))
}
// TO DO, this logic is on official tutorial GRAPHQL docs
function PrivateRoute({ children, ...rest }: Props) {
  return <Route {...rest}>{children}</Route>
}

export default function Routes() {
  const { oktaAuth } = useAppContext()

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
              <Route
                path={OKTA_REDIRECT_PATH}
                component={LoginCallback}
                exact
              />
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))}
              <PrivateRoute path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute path="/password-reset">
                <PasswordResetPage />
              </PrivateRoute>
            </Switch>
          </Security>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
