import { createMuiTheme } from '@material-ui/core'

import { COLORS } from 'constants/style'

export default function createCustomMuiTheme() {
  return createMuiTheme({
    palette: {
      primary: {
        main: `${COLORS.TURQUOISE}`,
        contrastText: '#fff',
      },
      text: {
        primary: COLORS.LIGHT_GREY3,
      },
      action: {
        disabledBackground: '#CCCCD6',
        disabled: '#FFFFFF'
      }
    },
    typography: {
      fontFamily: ['"Poppins"', 'sans-serif'].join(','),
    },

    overrides: {
      MuiChip: {
        root: {
          backgroundColor: '#F5F8FD',
          border: '1px solid #D4DBE8',
          borderRadius: '10px',
        },
      },

      MuiButton: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          boxShadow: 'none',
          fontWeight: 'normal',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
            outline: 'none',
          },
        },
        containedSecondary: {
          backgroundColor: '#EEF4FF',
          color: '#7C8DA3',
          border: 'none',
          '&:hover': {
            boxShadow: 'none',
            border: 'none',
            backgroundColor: '#dadfe9',
          },
          '&:focus': {
            boxShadow: 'none',
            outline: 'none',
            border: 'none',
          },
        },
      },
      MuiIconButton: {
        root: {
          color: COLORS.GRAY_BLUE,
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: 14,
          color: COLORS.DARK_GRAY2,
        },
      },

      MuiInputLabel: {
        outlined: {
          fontSize: 14,
          color: COLORS.DARK_GRAY2,
          transform: 'translate(0, 0) scale(1)',
        },
        shrink: {
          transform: 'translate(0, 0) scale(1) !important',
        },
        marginDense: {
          transform: 'translate(0, 0) scale(1) !important',
        },
      },
      MuiFormControl: {
        root: {},
      },
      MuiTabs: {
        root: {
          minHeight: 36,
        },
        indicator: {
          display: 'none',
        },
      },

      MuiTab: {
        root: {
          minHeight: 36,
          minWidth: '0 !important',
          padding: '6px 11px',
          textTransform: 'none',
          fontSize: 18,
          fontWeight: 600,
          '&$selected': {
            color: 'red',
          },
        },
        textColorInherit: {
          color: COLORS.GRAY_BLUE,
          borderRadius: 10,
          margin: '0 5px',
          '&.Mui-selected': {
            color: '#242953cc',
            backgroundColor: '#F2F7FF',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          borderColor: COLORS.LIGHT_GRAY_BLUE,
          'label + &': {
            marginTop: 24,
          },
          '&:hover $notchedOutline': {
            borderColor: COLORS.GRAY_BLUE,
          },
          '&& legend': {
            width: 0,
          },
          '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingTop: 2,
            paddingBottom: 2,
            minHeight: 40,
          },
          '&$focused $notchedOutline': {
            borderWidth: 1,
          },
          '&$disabled $notchedOutline': {
            borderColor: '#CCCCD6',
          },
        },
        marginDense: {
          'label + &': {
            marginTop: 24,
          },
        },
        inputMarginDense: {
          paddingTop: 12,
          paddingBottom: 11,
          'label + &': {
            marginTop: 10,
          },
        },
        input: {
          fontSize: 14,
          '&& :placeholder': {
            color: 'red',
          },
          padding: '12px 14px 11.5px',
        },
        notchedOutline: {
          borderColor: COLORS.LIGHT_GRAY_BLUE,
          borderRadius: '4px',
        },
      },
      MuiInputAdornment: {
        root: {
          color: COLORS.GRAY_BLUE,
        },
      },
      MuiInput: {
        root: {
          borderColor: COLORS.LIGHT_GRAY_BLUE,
          fontSize: '14px',
        },
      },
      MuiTableHead: {
        root: {
          '&& th': {
            border: 'none',
            color: '#404040',
            paddingBottom: '13px',
            fontSize: '12px',
            textTransform: 'uppercase',
          },
        },
      },
      MuiTable: {
        root: {
          borderCollapse: 'separate',
          color: COLORS.LIGHT_GREY3,
        },
      },
      MuiTableCell: {
        body: {
          color: COLORS.LIGHT_GREY3,
          fontSize: 14,
        },
        stickyHeader: {
          backgroundColor: COLORS.WHITE,
        },
      },
      MuiPaper: {
        elevation1: {
          background: '#FFFFFF',
          border: '1px solid #EAEBEC',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 24px rgba(207, 207, 207, 0.25)',
          borderRadius: '8px',
        },
      },
      MuiTableBody: {
        root: {
          borderCollapse: 'separate',
          '&& td': {
            borderTop: 'solid 1px #DFE6ED',
            borderBottom: 'none',
          },
          '&& tr:last-child td': {
            borderBottom: 'solid 1px #DFE6ED',
          },
          '&& td:first-child': {
            borderLeft: 'solid 1px #DFE6ED',
          },
          '&& td:last-child': {
            borderRight: 'solid 1px #DFE6ED',
          },
          '&& tr:first-child td:first-child': {
            borderTopLeftRadius: '10px',
          },
          '&& tr:last-child td:first-child': {
            borderBottomLeftRadius: '10px',
          },
          '&& tr:first-child td:last-child': {
            borderTopRightRadius: '10px',
          },
          '&& tr:last-child td:last-child': {
            borderBottomRightRadius: '10px',
          },
        },
      },
      /* @ts-ignore */
      MuiPickersModal: {
        dialogRoot: {
          '& .MuiDialogActions-root .MuiButtonBase-root': {
            color: 'white',
            backgroundColor: COLORS.TURQUOISE,
          },
          '& .MuiDialogActions-root .MuiButtonBase-root:first-child': {
            backgroundColor: '#EEF4FF',
            color: '#7C8DA3',
            border: 'none',
            marginRight: 10,
          },
        },
      },
    },
  })
}
