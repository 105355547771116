import { default as React, useEffect, useRef, useState } from 'react'

import { ApolloProvider } from '@apollo/client'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { OktaAuth } from '@okta/okta-auth-js'
import * as Sentry from '@sentry/react'

import 'assets/css/bootstrap.min.css'
import { Routes, AppVersionModal } from 'components/Index'
import { FullStory } from 'components/common/FullStory'
import {
  CROSS_DOMAIN_STORAGE_URL,
  GRAPHQL_INITIAL_STATE,
  LOCAL_STORAGE_KEYS,
  JLL_DOMAINS,
  OKTA_REDIRECT_PATH,
} from 'constants/static'
import AppContext from 'contexts/appContext'
import { GET_LOCAL_CACHED_DATA } from 'graphql/queries'
import createCustomMuiTheme from 'utils/createCustomMuiTheme'
import createGraphqlClient from 'utils/createGraphqlClient'
import getOktaCredentials from 'utils/getOktaCredentials'

import 'App.css'

require('dotenv').config()

const client = createGraphqlClient()
client.writeQuery({
  query: GET_LOCAL_CACHED_DATA,
  data: GRAPHQL_INITIAL_STATE,
})

// Or Create your Own theme:
const theme = createCustomMuiTheme()

if (!process.env.CI) {
  Sentry.init({
    dsn:
      'https://35118eabc9494e9e87e5eaff57d51f26@o425962.ingest.sentry.io/5545036',
    environment: process.env.REACT_APP_ENVIRONMENT || 'development',
    debug: true,
  })
}

const App = () => {
  const [pageTitle, setPageTitle] = useState<string | null>('')
  const crossDomainIFrame = useRef<HTMLIFrameElement>({} as HTMLIFrameElement)
  const [crossDomainIFrameLoaded, setCrossDomainIFrameLoaded] = useState(false)
  const [oktaAuth, setOktaAuth] = useState(() => {
    const { oktaIssuerUrl, oktaClientId } = getOktaCredentials(JLL_DOMAINS[0])
    return new OktaAuth({
      issuer: oktaIssuerUrl,
      clientId: oktaClientId,
      redirectUri: window.location.origin + OKTA_REDIRECT_PATH,
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
    })
  })

  useEffect(() => {
    if (crossDomainIFrame.current!!) {
      crossDomainIFrame.current.addEventListener(
        'load',
        onCrossDomainIframeLoad,
      )
    }
    window.addEventListener('message', handleCrossDomainMessages, false)

    return () => {
      window.removeEventListener('message', handleCrossDomainMessages)
    }
  }, [])

  const onCrossDomainIframeLoad = () => {
    if (crossDomainIFrame.current!!) {
      crossDomainIFrame!.current!.contentWindow!.postMessage(
        {
          action: 'get',
          key: LOCAL_STORAGE_KEYS.TOKEN,
        },
        CROSS_DOMAIN_STORAGE_URL || '*',
      )
    }
  }

  const saveCrossDomainToken = (value: string) => {
    if (!crossDomainIFrameLoaded) {
      return
    }

    crossDomainIFrame!.current!.contentWindow!.postMessage(
      {
        action: 'save',
        key: LOCAL_STORAGE_KEYS.TOKEN,
        value,
      },
      CROSS_DOMAIN_STORAGE_URL || '*',
    )
  }

  const handleCrossDomainMessages = (event: MessageEvent<any>) => {
    const {
      data: { action, key, value },
      origin,
    } = event

    if (action === 'returnData' && origin === CROSS_DOMAIN_STORAGE_URL) {
      switch (key) {
        case LOCAL_STORAGE_KEYS.TOKEN: {
          const tokenFromLocalStorage = localStorage.getItem(
            LOCAL_STORAGE_KEYS.TOKEN,
          )
          if (!tokenFromLocalStorage && value) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, value)
            const data = client.readQuery({ query: GET_LOCAL_CACHED_DATA })
            client.writeQuery({
              query: GET_LOCAL_CACHED_DATA,
              data: { ...data, ...{ isLoggedIn: true } },
            })
          }
        }
      }
      setCrossDomainIFrameLoaded(true)
    }
  }

  return (
    <>
      <iframe
        id="cross-domain-iframe"
        style={{ display: 'none' }}
        ref={crossDomainIFrame}
        src={CROSS_DOMAIN_STORAGE_URL}
      />
      {(!CROSS_DOMAIN_STORAGE_URL || crossDomainIFrameLoaded) && (
        <ApolloProvider client={client}>
          <AppContext.Provider
            value={{
              pageTitle,
              setPageTitle,
              saveCrossDomainToken,
              oktaAuth,
              setOktaAuth,
            }}
          >
            <MuiThemeProvider theme={theme}>
              <AppVersionModal />
              <Routes />
            </MuiThemeProvider>
            <FullStory />
          </AppContext.Provider>
        </ApolloProvider>
      )}
    </>
  )
}

export default App
