import React from 'react'

import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import styled from 'styled-components'

const CustomModal = ({ title, children, customStyles, ...rest }: any) => {
  return (
    <StyledRodal
      {...rest}
      customStyles={
        customStyles || {
          height: 'auto',
          bottom: 'auto',
          top: '50%',
          maxHeight: 'calc(100vh - 20px)',
          transform: 'translateY(-50%)',
        }
      }
    >
      <ModalTitle>{title}</ModalTitle>
      <ModalContent className="custom-modal-content">{children}</ModalContent>
    </StyledRodal>
  )
}

const ModalTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  color: #404040;
  font-size: 18px;
  min-height: 30px;
  margin-bottom: 20px;
`
const ModalContent = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
`

const StyledRodal = styled(Rodal)`
  .rodal-dialog {
    padding: 30px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }

  .rodal-close {
    top: 30px;
    right: 30px;
  }
`

export default CustomModal
