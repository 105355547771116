import { GET_CURRENT_USER } from 'graphql/queries'

import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'

type Props = {
  fetchPolicy?: WatchQueryFetchPolicy
}

const useUserData = (
  { fetchPolicy }: Props = { fetchPolicy: 'cache-only' },
) => {
  const { data, loading } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy,
  })

  if (loading) {
    return {} as User
  }

  return data?.user || ({} as User)
}

export default useUserData
